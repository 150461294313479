import { createReducer, on } from '@ngrx/store'
import { Action, ActionReducer } from '@ngrx/store/src/models'
import * as fromActions from './selected-match.actions'
import { SelectedMatchState } from './selected-match.state'

export const selectedMatchInitialState: SelectedMatchState = {
  editView: null,
  positionMatch: null,
  statusMatch: null,
  match: null,
  matchTemp: null,
  selected: null,
  loading: null,
  error: null,
};

export const selectedMatchReducer: ActionReducer<SelectedMatchState, Action> =
  createReducer<SelectedMatchState>(
    selectedMatchInitialState,
    on(
      fromActions.editSelectedMatchView,
      (state: SelectedMatchState, action): SelectedMatchState => ({
        ...state,
        editView: action.editView,
      })
    ),
    on(
      fromActions.restoreStatusMatch,
      (state: SelectedMatchState, action): SelectedMatchState => ({
        ...state,
        statusMatch: action.status
      })
    ),
    on(
      fromActions.editScore,
      (state: SelectedMatchState, action): SelectedMatchState => ({
        ...state,
        positionMatch: action.position,
        selected: action.selected,
        statusMatch: action.status,
        match: action.match,
        matchTemp: action.match,
      })
    ),
    on(
      fromActions.cancelEditScore,
      (state: SelectedMatchState, action): SelectedMatchState => ({
        ...selectedMatchInitialState,
        editView: action.editView,
        selected: false
      })
    ),
    on(
      fromActions.changeScore,
      (state: SelectedMatchState, action): SelectedMatchState => ({
        ...state,
        match: action.match
      })
    ),
    on(
      fromActions.updateMatch,
      (state: SelectedMatchState): SelectedMatchState => ({
        ...state,
        loading: true,
        error: null
      })
    ),
    on(
      fromActions.updateMatchSuccess,
      (action: SelectedMatchState): SelectedMatchState => ({
        ...selectedMatchInitialState,
        editView: action.editView,
        loading: false,
        error: null
      })
    ),
    on(
      fromActions.updateMatchError,
      (state: SelectedMatchState, action): SelectedMatchState => ({
        ...state,
        loading: false,
        error: action.error
      })
    )
  );
