import { inject } from '@angular/core'
import { MatchService } from '@app/services'
import * as fromMatches from '@app/store/matches'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { catchError, delay, map, mergeMap, of, tap } from 'rxjs'
import * as fromActions from './selected-match.actions'

export const updateMatch$ = createEffect(
  (
    store: Store = inject(Store),
    actions$ = inject(Actions),
    matchService: MatchService = inject(MatchService)
  ) => {
    return actions$.pipe(
      ofType(fromActions.updateMatch),
      mergeMap((action) => {
        return matchService.updateMatch(action.id, action.match).pipe(
          delay(1000),
          tap((): void => {
            store.dispatch(fromMatches.getMatchesAction());
          }),
          map(() => fromActions.updateMatchSuccess({ editView: action.editView })),
          catchError((error) => of(fromActions.updateMatchError(error)))
        );
      })
    );
  },
  { functional: true }
);