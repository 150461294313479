import { Match } from '@app/models/server'
import { createFeatureSelector, createSelector } from '@ngrx/store'
import { MatchState } from '..'
import { SelectedMatchState } from './selected-match.state'

export const selectSelectedMatchState =
  createFeatureSelector<MatchState>('selectedMatch');

export const selectGetSelectedMatch = createSelector(
  selectSelectedMatchState,
  (state: MatchState): SelectedMatchState => state.match
);

export const selectGetEditView = createSelector(
  selectGetSelectedMatch,
  (state: SelectedMatchState): boolean | null => state.editView
);

export const selectGetPositionSelectedMatch = createSelector(
  selectGetSelectedMatch,
  (state: SelectedMatchState): number | null => state.positionMatch
);

export const selectSelectedMatchStatus = createSelector(
  selectGetSelectedMatch,
  (state: SelectedMatchState): boolean | null => state.selected
);

export const selectStatusMatch = createSelector(
  selectGetSelectedMatch,
  (state: SelectedMatchState): number | null => state.statusMatch
);

export const selectGetMatch = createSelector(
  selectGetSelectedMatch,
  (state: SelectedMatchState): Match | null => state.match
);

export const selectGetMatchTemp = createSelector(
  selectGetSelectedMatch,
  (state: SelectedMatchState): Match | null => state.matchTemp
);

export const selectUpdateMatchLoading = createSelector(
  selectGetSelectedMatch,
  (state: SelectedMatchState): boolean | null => state.loading
);
