export enum SelectedMatchTypes {
  EDIT_SELECTED_MATCH_VIEW = '[Selected Match] Vista edición',
  RESTORE_STATUS_MATCH = '[Selected Match] Restaurar status match',
  CANCEL_EDIT_SCORE = '[Selected Match] Cancelar editar Score',
  EDIT_SCORE = '[Selected Match] Editar Score',
  CHANGE_SCORE = '[Selected Match] Score cambiado',

  UPDATE_MATCH = '[Selected Match] Actualizar match',
  UPDATE_MATCH_SUCCESS = '[Selected Match] Actualizar match: Éxito',
  UPDATE_MATCH_ERROR = '[Selected Match] Actualizar match: Error'
}