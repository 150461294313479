import { Match, MatchRequest } from '@app/models/server'
import { createAction, props } from '@ngrx/store'
import { SelectedMatchTypes } from './selected-match.types'

export const editSelectedMatchView = createAction(
  SelectedMatchTypes.EDIT_SELECTED_MATCH_VIEW,
  props<{ editView: boolean | null }>()
);

export const restoreStatusMatch = createAction(
  SelectedMatchTypes.RESTORE_STATUS_MATCH,
  props<{
    status: null;
  }>()
);

export const editScore = createAction(
  SelectedMatchTypes.EDIT_SCORE,
  props<{
    position: number | null;
    selected: boolean;
    status: number | null;
    match: Match | null;
    matchTemp: Match | null;
  }>()
);

export const cancelEditScore = createAction(
  SelectedMatchTypes.CANCEL_EDIT_SCORE,
  props<{
    editView: boolean | null;
  }>()
);

export const changeScore = createAction(
  SelectedMatchTypes.CHANGE_SCORE,
  props<{
    match: Match | null;
  }>()
);

export const updateMatch = createAction(
  SelectedMatchTypes.UPDATE_MATCH,
  props<{
    id: number;
    match: MatchRequest;
    editView: boolean | null;
  }>()
);

export const updateMatchSuccess = createAction(
  SelectedMatchTypes.UPDATE_MATCH_SUCCESS,
  props<{
    editView: boolean | null;
  }>()
);

export const updateMatchError = createAction(
  SelectedMatchTypes.UPDATE_MATCH_ERROR,
  props<{
    error: string
  }>()
);
